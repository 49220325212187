import React from 'react';

const Header = () => {
  return (
    <header className="relative h-40 bg-gradient-to-br from-purple-900 via-indigo-900 to-slate-900 flex items-center justify-center overflow-hidden shadow-xl">
      {/* Animated background texture */}
      <div className="absolute inset-0 opacity-50">
        <div className="h-full w-full bg-[radial-gradient(circle_at_center,rgba(165,180,252,0.1)_1px,transparent_1px)] bg-[size:20px_20px] animate-pulse"></div>
      </div>

      {/* Main content */}
      <div className="relative z-10 text-center space-y-3">
        {/* Logo/Title */}
        <h1 className="text-5xl font-bold tracking-tight">
          <span className="bg-gradient-to-r from-pink-300 via-purple-200 to-indigo-300 bg-clip-text text-transparent">
            Profilue
          </span>
          <span className="ml-1 bg-gradient-to-r from-pink-400 to-indigo-400 bg-clip-text text-transparent text-2xl align-super">
            ®
          </span>
        </h1>

        {/* Decorative line */}
        <div className="flex justify-center">
          <div className="h-[2px] w-24 bg-gradient-to-r from-pink-400/50 to-indigo-400/50 rounded-full"></div>
        </div>
      </div>

      {/* Subtle particles */}
      <div className="absolute inset-0 pointer-events-none">
        {[...Array(8)].map((_, i) => (
          <div
            key={i}
            className="absolute w-1.5 h-1.5 bg-gradient-to-r from-pink-400/30 to-indigo-400/30 rounded-full animate-float"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              animationDelay: `${i * 0.3}s`
            }}
          ></div>
        ))}
      </div>
    </header>
  );
};

export default Header;