import React from 'react';
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline';

const Footer = () => {
  return (
    <footer className="relative bg-gradient-to-tl from-purple-900 via-indigo-900 to-slate-900 border-t border-indigo-800/50">
      {/* Decorative particles */}
      <div className="absolute inset-0 opacity-30">
        {[...Array(15)].map((_, i) => (
          <div
            key={i}
            className="absolute w-1 h-1 bg-indigo-300 rounded-full animate-pulse"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              animationDelay: `${i * 0.2}s`
            }}
          ></div>
        ))}
      </div>

      <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row justify-between items-center space-y-8 md:space-y-0">
          {/* Contact Information */}
          <div className="space-y-4 text-center md:text-left">
            <h3 className="text-lg font-semibold bg-gradient-to-r from-pink-300 to-indigo-300 bg-clip-text text-transparent">
              Get in Touch
            </h3>
            <div className="space-y-2">
              <div className="flex items-center justify-center md:justify-start space-x-2">
                <EnvelopeIcon className="h-5 w-5 text-pink-300" />
                <a href="mailto:contact@profilue.com" className="text-indigo-100 hover:text-pink-300 transition-colors">
                info@profilue.com
                </a>
              </div>
              <div className="flex items-center justify-center md:justify-start space-x-2">
                <PhoneIcon className="h-5 w-5 text-pink-300" />
                <a href="tel:+11234567890" className="text-indigo-100 hover:text-pink-300 transition-colors">
                +974 33894345
                </a>
              </div>
            </div>
          </div>

          {/* Accent Element */}
          <div className="hidden md:block h-24 w-px bg-gradient-to-b from-transparent via-pink-300/50 to-transparent"></div>

          {/* Branding */}
          <div className="text-center">
            <h2 className="text-3xl font-bold bg-gradient-to-r from-pink-300 to-indigo-300 bg-clip-text text-transparent">
              Profilue<sup className="text-sm text-pink-300">®</sup>
            </h2>
            <p className="mt-2 text-sm text-indigo-200/80">
              Innovating Tomorrow's Solutions
            </p>
          </div>
        </div>

        {/* Copyright */}
        <div className="mt-12 border-t border-indigo-800/50 pt-8 text-center">
          <p className="text-xs text-indigo-400/80">
            © {new Date().getFullYear()} Profilue. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;