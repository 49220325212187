import React from 'react';
import Video from './components/Video';
import Footer from './components/Footer';
import Header from './components/Header';

function App() {
  return (
    <div className="flex flex-col min-h-screen">
      <Header/>
      <Video />
      <Footer />
    </div>
  );
}

export default App;