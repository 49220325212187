import React from 'react';
import videoFile from '../assets/sample.mp4'; // Import the video file

const Video = () => {
  return (
    <div className="w-full h-screen bg-black flex items-center justify-center overflow-hidden">
      <video
        autoPlay
        loop
        muted
        className="max-w-full max-h-full"
      >
        <source
          src={videoFile} // Use the imported video file
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default Video;